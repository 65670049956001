import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

$(function () {
	var $pswp = $('.pswp')[0];

	var getItems = function () {
		var items = [];

		$(this)
			.find('.gallery__lnk')
			.each(function () {
				var item;

				if ($(this).attr('data-html')) {
					item = {
						html: $(this).attr('data-html')
					};
				} else {
					var $href = $(this).attr('href'),
						$size = $(this)
							.data('size')
							.split('x'),
						$width = $size[0],
						$height = $size[1],
						$title = $(this).attr('title');

					item = {
						src: $href,
						w: $width,
						h: $height,
						title: $title
					};
				}

				items.push(item);
			});

		return items;
	};

	$('.js-gallery__list').on('click', '.gallery__lnk', function (e) {
		e.preventDefault();

		var $parent = $(this).closest('.js-gallery__list'),
			$wrap = $(this).parent();

		var gallery = new PhotoSwipe($pswp, PhotoSwipeUI_Default, getItems.call($parent), {
			index: $wrap.index(),
			showHideOpacity: true
		});

		gallery.init();
	});
});
